import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Box from '@mui/material/Box';

import { AppRoutes } from 'enums/Routes.enum';

import { useAuth } from 'hooks/Auth/useAuth';

import { ReactComponent as DashboardFilled } from 'assets/icons/dashboard-filled.svg';
import { ReactComponent as DashboardOutlined } from 'assets/icons/dashboard-outlined.svg';
import { ReactComponent as ComponentOutlined } from 'assets/icons/detail-outlined.svg';
import { ReactComponent as MachineOutlined } from 'assets/icons/machine-outlined.svg';
import { ReactComponent as SensorIcon } from 'assets/icons/sensor-outlined.svg';
import { MachineTree } from 'types/machine';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';

const WrapperStyles = {
  color: 'var(--gray-9)',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& .cls-1': {
    fill: 'var(--gray-9)',
  },
  '& svg': {
    height: '15px',
  },
};

const ItemStyles = {
  display: 'block',
  maxWidth: '330px',
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const getNodeWithPath = (
  items: MachineTree[],
  id: string
): {
  path: MachineTree[];
  item: MachineTree;
}[] => {
  const paths: {
    path: MachineTree[];
    item: MachineTree;
  }[] = [];
  items.forEach((item) => {
    if (item.id === id) {
      paths.push({ path: [item], item });
    } else {
      paths.push(
        ...getNodeWithPath(item.children, id).map((child) => ({
          path: [item, ...child.path],
          item: child.item,
        }))
      );
    }
  });
  return paths;
};

const getIcon = (item: MachineTree, firstItem: boolean) => {
  if (item.is_sensor) {
    return <SensorIcon style={{ height: '12px' }} />;
  }
  if (firstItem) {
    return <MachineOutlined />;
  }
  return <ComponentOutlined style={{ marginLeft: '2px' }} />;
};

export const Breadcrumbs: FC = () => {
  const { userData } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const isDashboardPage = pathname === AppRoutes.Dashboard;

  const { data: allItems } = useMachineTree();

  const nodeWithPath = useMemo(
    () => (allItems && id ? getNodeWithPath(allItems, id)[0] : undefined),
    [id, allItems]
  );

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: 'var(--gray-5)',
          margin: '0 0 0 4px',
        },
      }}
    >
      <Box
        sx={WrapperStyles}
        onClick={() => !isDashboardPage && navigate(AppRoutes.Dashboard)}
      >
        {isDashboardPage ? <DashboardFilled /> : <DashboardOutlined />}
        <Box
          sx={{
            ...ItemStyles,
            cursor: isDashboardPage ? 'auto' : 'pointer',
          }}
        >
          {userData?.tenant.config.dashboard || t('breadcrumbs.dashboard')}
        </Box>
      </Box>
      {nodeWithPath?.path.map((item, n) => {
        return (
          <Box
            key={item.id}
            onClick={() =>
              navigate(
                generatePath(
                  item.is_sensor ? AppRoutes.Model : AppRoutes.Detail,
                  { id: item.id }
                )
              )
            }
            sx={{
              ...WrapperStyles,
              ...(n === nodeWithPath.path.length - 1 && {
                color: 'var(--gray-12)',
                cursor: 'default',
                '& .cls-1': {
                  fill: 'var(--gray-12)',
                },
              }),
            }}
          >
            {getIcon(item, n === 0)}
            <Box sx={ItemStyles}>{item.label}</Box>
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};
