import React, { FC } from 'react';

import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';

import {
  DateTimeRangePicker,
  DateTimeRangePickerEventType,
} from '../DateTimeRangePicker/DateTimeRangePicker';

const TWO_MONTHS_IN_MINUTES = 30.44 * 24 * 60 * 2;
export const GlobalTimeRangeSelector: FC = () => {
  const {
    dateRange,
    setNewDateRange: setDateRange,
    setCustomStartDateRange,
    setCustomEndDateRange,
    customStartDate,
    customEndDate,
  } = useChartCacheData();

  const onSelectCustomRange = (event: DateTimeRangePickerEventType): void => {
    const { start, end, preset } = event;
    setDateRange(preset);
    setCustomStartDateRange(start);
    setCustomEndDateRange(end);
  };

  return (
    <DateTimeRangePicker
      start={customStartDate}
      end={customEndDate}
      preset={dateRange}
      maxDuration={TWO_MONTHS_IN_MINUTES}
      onChange={onSelectCustomRange}
    />
  );
};
