import React, { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';

import { fetchSensorTimeseries } from 'services/API/sensor';

import { getCustomTimeRangeQuery } from 'utils/helpers';
import { Box, Heading } from '@radix-ui/themes';

import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useQueries } from '@tanstack/react-query';
import { SensorChart } from 'views/ChartView/SensorChart';
import { useTranslation } from 'react-i18next';
import { Header } from 'components/Header';

export const SensorPage: FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { customStartDate, customEndDate } = useChartCacheData();
  const sensorIds = searchParams.getAll('sensorIds');

  const timeRangeQuery = useMemo(() => {
    return getCustomTimeRangeQuery(customStartDate, customEndDate);
  }, [customStartDate, customEndDate]);

  const sensors = useQueries({
    queries: sensorIds.map((id) => ({
      queryKey: sensorQueryKeys.filteredSensorData({
        timeRangeQuery,
        id,
      }),
      queryFn: () =>
        fetchSensorTimeseries({
          timeRangeQuery,
          id,
        }),
      retry: 0,
    })),
  });

  return (
    <Box>
      <Header withTimeRangeSelect>
        <Heading>{t('sensors', { count: Number.MAX_SAFE_INTEGER })}</Heading>
      </Header>
      <Box mx="5">
        {sensors?.map(({ data, isPending }) => (
          <Box key={data?.sensor.timeseries_id}>
            <SensorChart
              id={data?.sensor.timeseries_id || ''}
              title={data?.sensor.label || ''}
              unit={data?.sensor.unit || ''}
              data={data?.data || []}
              isPending={isPending}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
