import { LabelProps } from 'recharts';
import React, { FC } from 'react';
import { format, isSameDay } from 'date-fns';

import { BasicTooltip } from 'components/Tooltip';

import { CustomerEvent } from 'types/event';

interface SensorCustomLabelProps extends LabelProps {
  maxValue: number;
  event?: CustomerEvent;
  value?: number;
}

export const ChartCustomLabel: FC<SensorCustomLabelProps> = ({
  x,
  y,
  value,
  maxValue,
  event,
}) => {
  const offsetByX = 8;
  const offsetByY = 24;

  const updatedX = Number(x) - offsetByX;
  const updatedY = Number(y) - offsetByY;

  if (event && value && value > maxValue) {
    const start = new Date(event.start);
    const end = new Date(event.end);
    const dateFormat = isSameDay(start, end) ? 'HH:mm' : 'iii HH:mm';

    const startTime = format(start, dateFormat);
    const endTime = format(end, dateFormat);
    const tooltipTitle = `${event.label} (${startTime} - ${endTime})`;

    return (
      <BasicTooltip title={tooltipTitle}>
        <image
          href={event?.type.icon_path}
          x={updatedX}
          y={updatedY}
          width={16}
          height={16}
          style={{ cursor: 'pointer' }}
        />
      </BasicTooltip>
    );
  }

  return null;
};
