import { getChartEventsData, getTimeRangeInterval } from 'utils/helpers';

import { SensorData } from 'types/sensor';
import { CustomerEvent } from 'types/event';
import { GeneralChartData } from 'types/machine';
import {
  addMilliseconds,
  differenceInMinutes,
  subMilliseconds,
} from 'date-fns';
import { DateRanges } from 'enums/DateRanges.enum';

export const useModelSensorChartData = (
  maxValue: number,
  minValue: number,
  dateRange: DateRanges | undefined,
  chartData?: SensorData,
  eventsData?: CustomerEvent[],
  timeInterval?: { start?: string; end?: string }
): GeneralChartData[] => {
  let preparedSensorData: GeneralChartData[] = [];
  const dataLength = chartData?.curves[0].values.length || 0;
  for (let i = 0; i < dataLength; i += 1) {
    const obj = { date: 0, showTooltip: true };

    chartData?.curves.forEach(({ label, values }) => {
      obj[label] = values[i].value;
      obj.date = new Date(values[i].date).getTime();
    });

    const isItemInRange =
      timeInterval?.start && timeInterval?.end
        ? obj.date >= new Date(timeInterval.start.replace('Z', '')).getTime()
        : true;

    preparedSensorData = isItemInRange
      ? [...preparedSensorData, obj]
      : preparedSensorData;
  }

  const dataWithoutMissedValues = preparedSensorData.reduce<GeneralChartData[]>(
    (acc, rec, index) => {
      if (index === 0) return [...acc, rec];
      const prevItem = acc[index - 1];
      if (
        dateRange &&
        differenceInMinutes(new Date(rec.date), new Date(prevItem.date)) >
          getTimeRangeInterval(dateRange) &&
        prevItem[rec.label as string]
      ) {
        const nullPeriodStartItem: GeneralChartData = {
          date: addMilliseconds(new Date(prevItem.date), 1).getTime(),
        };
        const nullPeriodEndItem: GeneralChartData = {
          date: subMilliseconds(new Date(rec.date), 1).getTime(),
        };
        return [...acc, nullPeriodStartItem, nullPeriodEndItem, rec];
      }
      return [...acc, rec];
    },
    []
  );

  const firstPointTime = dataWithoutMissedValues[0]?.date;

  const lastPointTime =
    dataWithoutMissedValues[dataWithoutMissedValues.length - 1]?.date;

  if (!eventsData || !firstPointTime || !lastPointTime) {
    return [];
  }

  return [
    ...dataWithoutMissedValues,
    ...getChartEventsData(
      firstPointTime,
      lastPointTime,
      eventsData,
      maxValue,
      minValue
    ),
  ];
};
