import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UsersTable } from 'views/Administration/UsersTable';

import { Box, Dialog, Flex, Button, Heading } from '@radix-ui/themes';
import { UserForm } from 'views/Administration/UserForm';

export const AdministrationPage: FC = () => {
  const { t } = useTranslation();
  const [isCreateUserFormOpen, setCreateUserFormOpen] = useState(false);

  return (
    <>
      <Flex justify="between" align="center" height="63px" mx="5">
        <Heading>{t('administration.users')}</Heading>
        <Button onClick={() => setCreateUserFormOpen(true)}>
          {t('administration.createNewUser')}
        </Button>
      </Flex>
      <Box mx="5">
        <UsersTable />
      </Box>

      <Dialog.Root
        open={isCreateUserFormOpen}
        onOpenChange={setCreateUserFormOpen}
      >
        <Dialog.Content
          style={{
            maxWidth: '700px',
          }}
        >
          <Dialog.Title>{t('administration.createNewUser')}</Dialog.Title>
          <UserForm closeDialog={() => setCreateUserFormOpen(false)} />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
