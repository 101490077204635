import { Text, Button, Flex, Grid } from '@radix-ui/themes';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { useCalendar } from '@h6s/calendar';
import { useMachine } from '@xstate/react';
import clsx from 'clsx';
import { isSameDay, format, isWithinInterval, isBefore } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { Button as AriaButton } from '@ariakit/react';
import { States } from './date-time-picker-state';

import './DatePickerItem.css';

type CalendarMachine = typeof useMachine;

interface DatePickerProps {
  calendar: ReturnType<typeof useCalendar>;
  state: ReturnType<CalendarMachine>;
  onPrev?(): void;
  onNext?(): void;
}

export const DatePicker: FC<DatePickerProps> = ({
  state,
  calendar: { headers, body, ...calendar },
  onPrev,
  onNext,
}) => {
  const [current, send] = state;
  const months = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) =>
        format(new Date(1970, m), 'MMMM')
      ),
    []
  );

  const isStart = useCallback(
    (date: Date) => {
      const { start } = current.context;
      return isSameDay(start, date);
    },
    [current.context]
  );

  const isEnd = useCallback(
    (date: Date) => {
      const { end } = current.context;
      return isSameDay(end, date);
    },
    [current.context]
  );

  const isError = useMemo(() => {
    return current.value === States.ERROR;
  }, [current.value]);

  const isInRange = useCallback(
    (value: Date) => {
      const { start, end } = current.context;
      if (!end || isBefore(end, start)) return false;
      return isWithinInterval(value, {
        start,
        end,
      });
    },
    [current.context]
  );

  const isHoverable = (value: Date): boolean => {
    if (isStart(value) || isEnd(value)) return false;

    if (current.value === States.DONE) {
      return !isInRange(value);
    }

    return true;
  };

  const isInFuture = (value: Date): boolean => {
    return value > new Date();
  };

  const isEnabled = (value: Date): boolean => !isInFuture(value);

  return (
    <Flex direction="column" gap="2">
      <Flex align="center" justify="between" mb="1" mx="3">
        {onPrev && (
          <Flex align="center">
            <Button
              aria-label="previous month"
              size="3"
              variant="ghost"
              color="gray"
              onClick={onPrev}
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex={1}
            >
              <ChevronLeftIcon />
            </Button>
          </Flex>
        )}
        <Flex position="relative" mx="auto">
          <Text size="2" weight="bold">
            {months[calendar.cursorDate.getMonth()]}{' '}
            {calendar.cursorDate.getFullYear()}
          </Text>
        </Flex>
        {onNext && (
          <Flex align="center">
            <Button
              aria-label="next month"
              size="3"
              variant="ghost"
              color="gray"
              onClick={onNext}
            >
              <ChevronRightIcon />
            </Button>
          </Flex>
        )}
      </Flex>
      <Grid columns="7" gapY="1">
        {headers.weekDays.map(({ key, value }) => (
          <Text size="2" weight="medium" key={key} align="center">
            {format(value, 'EEEEEE')}
          </Text>
        ))}
        {body.value.map(({ value: days }) =>
          days.map(({ key, value, isCurrentMonth, date }) => (
            <AriaButton
              key={key}
              aria-disabled={!isEnabled}
              className={clsx('button', {
                isToday: isSameDay(value, new Date()),
                isOtherMonth: !isCurrentMonth,
                isHighlighted: isStart(value) || isEnd(value),
                isSelected: isStart(value) || isInRange(value),
                isError: isInRange(value) && isError,
                isFocusable: isHoverable(value) && isEnabled(value),
                isDisabled: !isEnabled(value),
              })}
              onClick={() => {
                if (!isEnabled(value)) return;

                if (current.can({ type: 'SELECT_START' })) {
                  send({ type: 'SELECT_START', start: value });
                } else if (current.can({ type: 'SELECT_END' })) {
                  send({ type: 'SELECT_END', end: value });
                }
              }}
            >
              {date}
            </AriaButton>
          ))
        )}
      </Grid>
    </Flex>
  );
};
