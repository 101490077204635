import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Box, TablePagination } from '@mui/material';

import { BasicTable } from 'components/BasicTable';
import { EmptyMessage } from 'components/EmptyMessage/EmptyMessage';

import { AppRoutes } from 'enums/Routes.enum';
import { useTableSorting } from 'hooks/Table/useTableSorting';

import { useFindMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { columns } from './columns';

export const DetailsTable: FC = memo(() => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { sortingOrder, sortingType, onSetSortingType } = useTableSorting();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data: machineTree, isPending } = useFindMachineTree(id);

  const itemList = machineTree?.[0]?.children?.slice(
    rowsPerPage * page,
    rowsPerPage * (page + 1)
  );

  const sortedItemList = itemList?.sort((a, b) => {
    return a.label.localeCompare(b.label) * (sortingOrder === 'asc' ? 1 : -1);
  });

  return (
    <Box width="100%">
      <BasicTable
        columns={columns}
        data={sortedItemList || []}
        onClickByRow={(item) =>
          navigate(
            generatePath(item.is_sensor ? AppRoutes.Model : AppRoutes.Detail, {
              id: item.id,
            })
          )
        }
        onClickByColumnHead={onSetSortingType}
        sortingOrder={sortingOrder}
        sortingType={sortingType}
        pagination={
          !!machineTree && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              count={machineTree[0].children.length || 0}
              component="div"
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                marginTop: '32px',
                borderTop: '1px solid',
                borderColor: (theme) => theme.palette.custom.borderColor,
              }}
            />
          )
        }
      />
      {!isPending && !machineTree?.length && (
        <EmptyMessage message={t('emptyMessage.details')} />
      )}
    </Box>
  );
});
