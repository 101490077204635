import { FetchFilterProps } from 'services/API/machine';

export const eventsQueryKeys = {
  events: ['events'],
  infinityPaginatedEvents: (
    filters: FetchFilterProps
  ): (string | { filters: FetchFilterProps })[] => [
    ...eventsQueryKeys.events,
    { filters },
  ],
};
