import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';

import Box from '@mui/material/Box';

import { Flex, Inset, Separator, Spinner, Text } from '@radix-ui/themes';

import { If } from 'components/If';

import { CustomerEventsWithPagination, fetchEvents } from 'services/API/event';

import { CustomerEvent } from 'types/event';
import { APIError } from 'types/apiError';

import { eventsQueryKeys } from 'enums/EventsQueryKeys.enum';

import { STALE_DATA_TIME } from 'utils/constants';
import { format, parseISO } from 'date-fns';
import { EventCard } from 'components/EventCard/EventCard';
import { useParams } from 'react-router-dom';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { getCustomTimeRangeQuery } from 'utils/helpers';
import { FetchFilterProps } from 'services/API/machine';

interface EventListProps {
  selectedDate: Date;
}

const showMonthTitle = (
  event: CustomerEvent,
  eventIndex: number,
  events?: CustomerEvent[]
): boolean => {
  if (!events) return false;
  const eventEnd = parseISO(event.end);
  const prevEvent = events[eventIndex - 1];
  const prevEventEnd = prevEvent && parseISO(prevEvent.end);

  return eventIndex === 0 || eventEnd.getMonth() !== prevEventEnd.getMonth();
};

export const EventList: FC<EventListProps> = ({ selectedDate }) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const { id } = useParams<{ id: string }>();
  const { customEndDate, customStartDate } = useChartCacheData();

  const options: FetchFilterProps = {
    timeRangeQuery: getCustomTimeRangeQuery(customStartDate, customEndDate),
    id,
  };

  const { data, fetchNextPage, isPending, isFetchingNextPage } =
    useInfiniteQuery<CustomerEventsWithPagination, APIError>({
      initialPageParam: selectedDate.toISOString(),
      queryKey: eventsQueryKeys.infinityPaginatedEvents(options),
      staleTime: STALE_DATA_TIME,
      queryFn: () => fetchEvents(options),
      retry: false,
      getNextPageParam: (lastPage) => lastPage.nextStart,
    });

  const allEvents = data?.pages.reduce((acc, rec) => {
    return [...acc, ...rec.data];
  }, [] as CustomerEvent[]);

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView, fetchNextPage]);

  if (isPending) {
    return (
      <Flex p="2" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  if (!isPending && !allEvents?.length) {
    return (
      <Flex p="2" align="center" justify="center">
        <Text size="2">{t('events.noEvents')}</Text>
      </Flex>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
      }}
    >
      <Flex direction="column" gap="3" width="100%" mt="-4">
        {allEvents?.map((event, index) => (
          <React.Fragment key={event.id}>
            {showMonthTitle(event, index, allEvents) ? (
              <Inset side="x" key={`date-${event.id}`}>
                <Flex style={{ background: 'var(--gray-3)' }} px="4" py="1">
                  <Text size="2" weight="medium">
                    {format(parseISO(event.end), 'LLLL yyyy')}
                  </Text>
                </Flex>
              </Inset>
            ) : (
              <Separator size="4" key={`separator-${event.id}`} />
            )}
            <EventCard
              event={event}
              selectedDate={selectedDate}
              key={event.id}
            />
          </React.Fragment>
        ))}
      </Flex>
      <If condition={isFetchingNextPage}>
        <Flex p="2" align="center" justify="center">
          <Spinner />
        </Flex>
      </If>
      <Box ref={ref} />
    </Box>
  );
};
