import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { CustomXAxisTick } from 'components/CustomXAxisTick';
import { ChartCustomLabel } from 'components/ChartCustomLabel';
import { If } from 'components/If';

import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { DateRanges } from 'enums/DateRanges.enum';

import { fetchSensorData } from 'services/API/sensor';
import { FetchFilterProps, fetchMachineEvents } from 'services/API/machine';

import { useModelSensorChartData } from 'hooks/ChartData/useModelSensorChartData';
import { useSensorNoDataEvent } from 'hooks/ChartData/useSensorNoDataEvent';
import { useSensorYAxisRange } from 'hooks/ChartData/useSensorYAxisRange';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';

import { getIsIntervalMoreTwoWeeks } from 'utils/helpers';
import {
  REFRESH_GENERAL_OVERVIEW_DEFAULT,
  REFRESH_SENSOR_DATA_INTERVAL,
} from 'utils/constants';

import {
  CustomYAxisRange,
  SensorData,
  SensorReadingResponse,
} from 'types/sensor';
import { CustomerEvent } from 'types/event';

import { theme } from 'styles/theme';

import { differenceInDays } from 'date-fns';
import { keepPreviousDataOnRefetch } from 'utils/helpers/queryHelpers';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { SensorCustomLegend } from './SensorCustomLegend';
import { filterEvents } from '../GeneralOverviewChart';
import { SensorChartSettings } from './SensorChartSettings';
import { ChartTooltip } from '../ChartTooltip';
import { ChartCursor } from '../ChartCursor';

const strokeColors = [theme.lightBlue, theme.darkBlue, theme.blue];

const getCurvesValues = (data: SensorData | undefined): number[] => {
  if (!data) return [];
  return data.curves
    .reduce((acc, curve) => {
      const res = curve.values.map((point) => point.value);
      return [...acc, ...res];
    }, [] as (string | number)[])
    .filter((item) => typeof item === 'number') as number[];
};

const getPrecision = (value: number) => {
  switch (true) {
    case value === 0:
      return 1;
    case value < 10 && value > -10:
      return 3;
    case (value >= 10 && value < 100) || (value <= -10 && value > -100):
      return 4;
    default:
      return 5;
  }
};

const getTicks = (min: number, max: number) => {
  const quarterDiff = (max - min) / 4;

  return [
    min,
    min + quarterDiff,
    min + 2 * quarterDiff,
    min + 3 * quarterDiff,
    max,
  ];
};

// From the Recharts documentation: tick?:((props: any) => ReactElement<SVGElement>)
// eslint-disable-next-line
const CustomYAxisTick: FC<any> = (props) => {
  const { x, y, payload, fill } = props;

  if (!payload) return null;

  return (
    <text x={x} y={y} textAnchor="middle" dx="-2.4em" fill={fill}>
      <tspan x={x}>
        {Number.isInteger(payload.value) || payload.value >= 100
          ? Math.round(payload.value)
          : parseFloat(payload.value.toPrecision(getPrecision(+payload.value)))}
      </tspan>
    </text>
  );
};

const getDomainMinMax = (
  min: number,
  max: number
): [domainMin: number, domainMax: number] => {
  if (min === Infinity || max === -Infinity) {
    return [min === Infinity ? 0 : min, max === -Infinity ? 100 : max];
  }

  if (min !== max) {
    const minCorrected = min < 0 ? min + min * 0.02 : min - min * 0.02;
    const maxCorrected = max < 0 ? max - max * 0.0025 : max + max * 0.0025;

    return [minCorrected, maxCorrected];
  }
  if (min === max && min !== 0) return [min - min / 2, max + max / 2];
  return [0, 0.1];
};

interface SensorChartProps {
  dataKey: string;
  options: FetchFilterProps;
  liveUpdate?: boolean;
}

export const ModelSensorChart: FC<SensorChartProps> = ({
  dataKey,
  options,
  liveUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const { customEndDate, customStartDate, dateRange } = useChartCacheData();
  const hourView = dateRange === DateRanges.HOUR;
  const [data, setData] = useState<SensorReadingResponse | null>(null);
  const { yAxisRange, onChangeYAxisRange } = useSensorYAxisRange(
    options.id,
    dataKey
  );
  const [customYAxisRange, setCustomYAxisRange] =
    useState<CustomYAxisRange | null>(yAxisRange);
  const onChangeCustomYAxisRange = useCallback(
    (value: CustomYAxisRange | null) => {
      setCustomYAxisRange(value);
      onChangeYAxisRange(value);
    },
    [onChangeYAxisRange]
  );

  const isLongDateFormat =
    !dateRange && getIsIntervalMoreTwoWeeks(customStartDate, customEndDate);

  const isRangeDateMoreThanOneDay = useCallback(() => {
    if (customEndDate && customStartDate) {
      return differenceInDays(customEndDate, customStartDate) > 1;
    }
    return false;
  }, [customEndDate, customStartDate]);

  const refetchInterval = hourView
    ? REFRESH_SENSOR_DATA_INTERVAL
    : REFRESH_GENERAL_OVERVIEW_DEFAULT;

  const { data: sensorData, isPending } =
    useQueryWithError<SensorReadingResponse>({
      queryKey: sensorQueryKeys.filteredSensorData(options),
      queryFn: () => fetchSensorData(options),
      retry: 0,
      placeholderData: (previousData, previousQuery) =>
        keepPreviousDataOnRefetch(previousData, previousQuery, options),
    });

  useEffect(() => {
    if (sensorData) {
      setData(sensorData);
    }
  }, [sensorData]);

  const renderCustomTooltip = useCallback(
    ({ payload: payloads }: TooltipProps<string, string>) => {
      if (!payloads?.length) {
        return null;
      }

      const { date, showTooltip } = payloads[0].payload;
      return showTooltip ? (
        <ChartTooltip
          date={date}
          payloads={payloads}
          valueFormatter={(value) =>
            value === 'NaN' ? t('emptyMessage.default') : value || '0'
          }
          isRangeMoreOneDay={isRangeDateMoreThanOneDay()}
        />
      ) : null;
    },
    [isRangeDateMoreThanOneDay, t]
  );

  const { data: eventsData = [] } = useQueryWithError<CustomerEvent[]>({
    queryKey: machinesQueryKeys.filteredMachineEvents(options),
    queryFn: () => fetchMachineEvents(options),
    retry: 0,
    refetchInterval,
  });

  const chartData = (data && data[dataKey]) || undefined;

  const maxChartValue = Math.max(...getCurvesValues(chartData));
  const minChartValue = Math.min(...getCurvesValues(chartData));
  const customDomainMinMax = [
    typeof customYAxisRange?.min === 'number'
      ? customYAxisRange.min
      : minChartValue,
    typeof customYAxisRange?.max === 'number'
      ? customYAxisRange.max
      : maxChartValue,
  ];
  const [domainMin, domainMax] = customYAxisRange
    ? customDomainMinMax
    : getDomainMinMax(minChartValue, maxChartValue);
  const zeroChart = minChartValue === maxChartValue && minChartValue === 0;

  const noDataEvents = useSensorNoDataEvent(chartData?.curves || []);
  const finalEventsData =
    [...eventsData, ...noDataEvents].reduce<CustomerEvent[]>((acc, rec) => {
      return [...acc, rec, { ...rec, id: `${rec.id}_minus` }];
    }, []) ?? [];

  const sensorChartData = useModelSensorChartData(
    domainMax,
    domainMin,
    dateRange,
    chartData,
    liveUpdate && hourView ? filterEvents(finalEventsData) : finalEventsData,
    options.timeRangeQuery
  );

  const availableCurves = data
    ? data[dataKey].curves.map((item) => item.label)
    : [];

  const chartTitle = data && !!data[dataKey].title ? data[dataKey].title : '';

  const chartUnit =
    data && !!data[dataKey].unit ? `(${data[dataKey].unit})` : '';

  return (
    <Box
      sx={{
        border: '1px solid',
        width: '100%',
        height: 'fit-content',
        borderRadius: '4px',
        marginBottom: '10px',
        position: 'relative',
        borderColor: (theme) => theme.palette.custom.borderColor,
        backgroundColor: (theme) => theme.palette.custom.white,
      }}
    >
      <Box
        sx={{
          borderBottom: `${isOpen ? 1 : 0}px solid`,
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '157%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '34px',
          borderColor: (theme) => theme.palette.custom.borderColor,
        }}
      >
        {isPending ? t('charts.loading') : `${chartTitle} ${chartUnit}`}

        <If condition={isOpen}>
          <SensorChartSettings
            value={customYAxisRange}
            setValue={onChangeCustomYAxisRange}
            initialValue={{ min: domainMin, max: domainMax }}
          />
        </If>

        <IconButton
          aria-label="back"
          sx={{
            width: 24,
            height: 24,
            borderRadius: 0,
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {isOpen && (
        <ResponsiveContainer width="100%" height={240}>
          <ComposedChart
            syncId="syncId"
            data={sensorChartData}
            margin={{
              top: 30,
              right: 40,
              left: 30,
              bottom: 20,
            }}
          >
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              interval="preserveStart"
              tick={<CustomXAxisTick isLongDateFormat={isLongDateFormat} />}
              type="number"
              scale="time"
              domain={['auto', 'auto']}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              allowDataOverflow
              tick={<CustomYAxisTick />}
              domain={[domainMin, domainMax]}
              ticks={getTicks(domainMin, domainMax)}
            />
            {finalEventsData?.map((item) => {
              return (
                <Area
                  type="step"
                  dataKey={item.id}
                  fill={item.type.hexcolor}
                  fillOpacity={0.1}
                  strokeWidth={0}
                  activeDot={false}
                  key={item.id}
                  id={item.id}
                  isAnimationActive={false}
                  label={<ChartCustomLabel maxValue={domainMax} event={item} />}
                />
              );
            })}
            <CartesianGrid opacity={0.7} />

            {availableCurves.map((curve, index) => {
              return (
                <Line
                  type="linear"
                  dataKey={curve}
                  stroke={strokeColors[index]}
                  fillOpacity={0}
                  strokeWidth={zeroChart ? 4 : 2}
                  activeDot={false}
                  dot={false}
                  key={curve}
                  isAnimationActive={false}
                />
              );
            })}
            <Legend
              verticalAlign="bottom"
              height={30}
              content={<SensorCustomLegend eventsData={finalEventsData} />}
              wrapperStyle={{ bottom: 0 }}
            />
            <Tooltip
              content={renderCustomTooltip}
              isAnimationActive={false}
              cursor={<ChartCursor />}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};
