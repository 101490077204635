import React, { FC, ReactNode, useCallback, useState } from 'react';
import { DateRanges } from 'enums/DateRanges.enum';
import { ChartContextProvider } from 'hooks/ChartData/useChartCacheData';
import { subDays } from 'date-fns';

interface Props {
  children: ReactNode;
}

export const ChartDataProvider: FC<Props> = ({ children }) => {
  const endDate = new Date();
  const startDate = subDays(new Date(), 1);
  const [customStartDate, setCustomStartDate] = useState<Date>(startDate);
  const [customEndDate, setCustomEndDate] = useState<Date>(endDate);
  const [dateRange, setDateRange] = useState<DateRanges | undefined>(
    DateRanges.DAY
  );

  const setCustomStartDateRange = useCallback((startDate: Date) => {
    setCustomStartDate(startDate);
  }, []);
  const setCustomEndDateRange = useCallback((endDate: Date) => {
    setCustomEndDate(endDate);
  }, []);
  const setNewDateRange = useCallback(
    (preset: DateRanges | undefined) => setDateRange(preset),
    []
  );

  return (
    <ChartContextProvider
      value={{
        dateRange,
        setNewDateRange,
        customStartDate,
        customEndDate,
        setCustomStartDateRange,
        setCustomEndDateRange,
      }}
    >
      {children}
    </ChartContextProvider>
  );
};
