import React, { FC, ReactNode, useCallback, useState } from 'react';
import { AuthContextProvider } from 'hooks/Auth/useAuth';
import { LocalStorage } from 'services/LocalStorage';
import { microsoftSignOff } from 'services/API/auth';
import { User } from 'types/user';
import { STORE_AUTH_TOKEN, STORE_TENANT_ID } from 'utils/constants';
import { api } from 'services/API/client';

interface Props {
  children: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const [userData, setUserData] = useState<User | null>(null);
  const rehydratedTenantId = LocalStorage.getItem<string>(STORE_TENANT_ID);

  const [tenantId, setTenantId] = useState<string | null>(rehydratedTenantId);
  const onChangeUserData = useCallback((userData: User | null) => {
    setUserData(userData);
  }, []);

  const onChangeTenantId = useCallback((tenantId: string) => {
    setTenantId(tenantId);
    api.defaults.headers.common['X-Tenant-Id'] = tenantId;
    LocalStorage.setItem(STORE_TENANT_ID, tenantId);
  }, []);

  const logOut = useCallback(() => {
    LocalStorage.removeItem(STORE_AUTH_TOKEN);
    LocalStorage.removeItem(STORE_TENANT_ID);
    setUserData(null);
    microsoftSignOff();
  }, []);

  return (
    <AuthContextProvider
      value={{
        logOut,
        userData,
        onChangeUserData,
        tenantId,
        onChangeTenantId,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};
