import React, { FC, ReactNode } from 'react';

import { Flex, Box } from '@radix-ui/themes';

import { PermissionsGate } from 'components/PermissionsGate';
import { TenantSwitcher } from 'components/TenantSwitcher';

import { UserScopes } from 'enums/UserScopes.enum';
import { GlobalTimeRangeSelector } from 'components/GlobalTimeRangeSelector';

interface HeaderProps {
  children: ReactNode;
  withTimeRangeSelect?: boolean;
}

export const Header: FC<HeaderProps> = ({ children, withTimeRangeSelect }) => {
  return (
    <Flex align="center" justify="between" mx="5" height="75px">
      {children}

      <Box position="absolute" left="calc(50% + 105px)">
        <PermissionsGate scopes={[UserScopes.TenantSwitcher]}>
          <TenantSwitcher />
        </PermissionsGate>
      </Box>

      {withTimeRangeSelect && <GlobalTimeRangeSelector />}
    </Flex>
  );
};
