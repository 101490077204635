import { addSeconds, isSameHour } from 'date-fns';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useInterval } from 'hooks/ChartData/useInterval';
import { REFRESH_SENSOR_DATA_INTERVAL } from 'utils/constants';
import { DateRanges } from 'enums/DateRanges.enum';
import { getTimeForDateRange } from '../../utils/helpers';

export const useLiveUpdateChartOptions = () => {
  const {
    dateRange,
    setCustomEndDateRange,
    setCustomStartDateRange,
    customEndDate,
  } = useChartCacheData();
  const liveUpdate = isSameHour(new Date(), customEndDate);
  const isHourView = dateRange === DateRanges.HOUR;

  const refetchInterval = REFRESH_SENSOR_DATA_INTERVAL;

  useInterval(() => {
    if (liveUpdate && isHourView) {
      if (addSeconds(customEndDate, refetchInterval) < new Date()) {
        const { start, end } = getTimeForDateRange(DateRanges.HOUR);
        setCustomStartDateRange(start);
        setCustomEndDateRange(end);
      }
    }
  }, refetchInterval);

  return [liveUpdate];
};
