import { DateRanges } from 'enums/DateRanges.enum';
import { Flex, Text } from '@radix-ui/themes';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashIcon } from '@radix-ui/react-icons';

interface DateTimeRangeSelectionProps {
  preset: DateRanges;
  start: Date;
  end: Date;
}

export const DateTimeRangeSelection = ({
  preset,
  start,
  end,
}: DateTimeRangeSelectionProps) => {
  const [t] = useTranslation();
  const formatDate = (datetime: Date) => {
    return format(datetime, 'dd. MMM yyyy HH:mm');
  };

  if (preset) {
    return <Text>{t(`DateRanges.${preset}`)}</Text>;
  }

  return (
    <Flex gap="1" align="center">
      <Flex width="155px" justify="end">
        <Text>{start && formatDate(start)}</Text>
      </Flex>
      <DashIcon width="22px" height="auto" />
      <Flex width="155px">
        <Text>{end ? formatDate(end) : t('endDate')}</Text>
      </Flex>
    </Flex>
  );
};
