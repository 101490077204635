import React, { useContext } from 'react';
import { DateRanges } from 'enums/DateRanges.enum';

interface ChartContextData {
  customStartDate?: Date | null;
  customEndDate: Date;
  dateRange: DateRanges | undefined;
  setNewDateRange: (dateRange: DateRanges | undefined) => void;
  setCustomStartDateRange: (date: Date) => void;
  setCustomEndDateRange: (date: Date) => void;
}

export const chartContext = React.createContext<ChartContextData>({
  customStartDate: null,
  customEndDate: new Date(),
  dateRange: DateRanges.DAY,
  setNewDateRange: () => {},
  setCustomStartDateRange: () => {},
  setCustomEndDateRange: () => {},
});

export const ChartContextProvider = chartContext.Provider;

export const useChartCacheData = (): ChartContextData =>
  useContext(chartContext);
