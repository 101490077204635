import React from 'react';
import {
  Link,
  Card,
  Flex,
  DataList,
  Grid,
  Heading,
  Spinner,
} from '@radix-ui/themes';
import { generatePath, Link as BrowserLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MachineTree } from 'types/machine';
import { AppRoutes } from 'enums/Routes.enum';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { Header } from 'components/Header';

const getAllLeafNodesWithPath = (
  items: MachineTree[]
): {
  path: MachineTree[];
  item: MachineTree;
}[] => {
  const paths: {
    path: MachineTree[];
    item: MachineTree;
  }[] = [];
  items.forEach((item) => {
    if (item.children.length === 0) {
      paths.push({ path: [item], item });
    } else {
      paths.push(
        ...getAllLeafNodesWithPath(item.children).map((child) => ({
          path: [item, ...child.path],
          item: child.item,
        }))
      );
    }
  });
  return paths;
};

export const ModelsPage = () => {
  const { t } = useTranslation();
  const { data: allItems, isPending } = useMachineTree();
  const models = getAllLeafNodesWithPath(allItems || []);
  return (
    <div style={{ paddingBottom: '12px' }}>
      <Header>
        <Heading>{t('models', { count: 2 })}</Heading>
      </Header>
      {isPending ? (
        <Flex justify="center" p="3">
          <Spinner />
        </Flex>
      ) : (
        <Grid
          columns={{
            initial: '3',
            lg: '4',
            xl: '5',
          }}
          gap="3"
          mx="5"
        >
          {models?.map((model) => (
            <Card key={model.item.id} asChild>
              <BrowserLink
                to={generatePath(AppRoutes.Model, { id: model.item.id })}
              >
                <Heading size="4">{model.item.label}</Heading>
                <DataList.Root my="2" size="1">
                  {model.path.map((parent: MachineTree, n: number) => {
                    if (n === model.path.length - 1) return null;
                    return (
                      <DataList.Item key={parent.id}>
                        <DataList.Label>
                          {n === 0
                            ? t('machines', { count: 1 })
                            : `${t('components', { count: 1 })} ${n}`}
                        </DataList.Label>
                        <DataList.Value>
                          {
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link asChild>
                              <BrowserLink
                                to={generatePath(AppRoutes.Detail, {
                                  id: parent.id,
                                })}
                              >
                                {parent.label}
                              </BrowserLink>
                            </Link>
                          }
                        </DataList.Value>
                      </DataList.Item>
                    );
                  })}
                </DataList.Root>
              </BrowserLink>
            </Card>
          ))}
        </Grid>
      )}
    </div>
  );
};
