import React, { FC } from 'react';

import { MachinesTable } from 'views/Dashboard/MachinesTable';
import { OverallStates } from 'views/Dashboard/OverallStates';

import { MostCriticalTable } from 'components/MostCriticalTable';
import { Header } from 'components/Header';
import { Flex, Box, Heading } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';

export const DashboardPage: FC = () => {
  const [t] = useTranslation();
  return (
    <>
      <Header>
        <Heading>{t('dashboard')}</Heading>
      </Header>
      <Box mx="5">
        <Flex gap="2" mb="2">
          <OverallStates />
          <MostCriticalTable isMachinesList />
        </Flex>
        <Flex gap="2" mb="2">
          <MachinesTable />
        </Flex>
      </Box>
    </>
  );
};
