import { DateRanges } from 'enums/DateRanges.enum';

export const getTimeRangeInterval = (range: DateRanges) => {
  switch (range) {
    case DateRanges.HOUR:
      return 3;
    case DateRanges.DAY:
      return 15;
    default:
      return 90;
  }
};
