import { getChartEventsData } from 'utils/helpers';

import { CustomerEvent } from 'types/event';
import { GeneralChartData, MachineHealthData } from 'types/machine';
import { addMinutes, addSeconds, intervalToDuration } from 'date-fns';
import { TRAINING_INTERVAL } from 'utils/constants';
import { useMemo } from 'react';
import { DateRanges } from 'enums/DateRanges.enum';

export const useGeneralChartData = (
  dateRange: DateRanges | undefined,
  healthStatusData?: MachineHealthData[],
  eventsData?: CustomerEvent[]
): GeneralChartData[] => {
  const preparedHealthData = useMemo(
    () =>
      healthStatusData?.map((item) => {
        return {
          ...item,
          showTooltip: true,
          // converting date from the ISO string to milliseconds
          date: new Date(item.date).getTime(),
        } as GeneralChartData;
      }) ?? [],
    [healthStatusData]
  );
  const firstPointTime = preparedHealthData[0]?.date;

  const lastPointTime = preparedHealthData[preparedHealthData.length - 1]?.date;

  const fakeForecastData = useMemo(() => {
    const forecast = [];
    if (lastPointTime < new Date().getTime() && dateRange === DateRanges.HOUR) {
      // find last element with health !== 'NaN'
      const lastNonNanHealth = preparedHealthData
        .slice()
        .reverse()
        .find((item) => item.health !== 'NaN');

      if (
        lastNonNanHealth &&
        addSeconds(lastNonNanHealth.date, TRAINING_INTERVAL) > new Date()
      ) {
        const startForecast = {
          ...lastNonNanHealth,
          health: 'NaN',
          forecast: lastNonNanHealth?.health,
        };
        const duration = intervalToDuration({
          start: lastNonNanHealth.date,
          end: new Date(),
        });
        const between = [...Array(duration.minutes).keys()].map((i) => ({
          date: addMinutes(lastNonNanHealth.date, i + 1).getTime(),
          health: 'NaN',
          forecast: Math.min(
            100,
            Math.max(
              0,
              (Math.random() - 0.5) * 20 + Number(lastNonNanHealth?.health)
            )
          ),
        }));

        const endForecast = {
          date: new Date().getTime(),
          health: 'NaN',
          forecast: lastNonNanHealth?.health,
        };

        forecast.push(startForecast, ...between, endForecast);
      }
    }
    return forecast;
  }, [preparedHealthData, lastPointTime, dateRange]);

  if (!eventsData || !firstPointTime || !lastPointTime) {
    return [];
  }

  const healthDataWithForecast = preparedHealthData.concat(fakeForecastData);

  return [
    ...healthDataWithForecast,
    ...getChartEventsData(firstPointTime, lastPointTime, eventsData),
  ];
};
