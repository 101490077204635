import React, { FC } from 'react';
import { isSameDay, format, isToday } from 'date-fns';

// From the Recharts documentation: tick?:((props: any) => ReactElement<SVGElement>)
// eslint-disable-next-line
export const CustomXAxisTick: FC<any> = (props) => {
  const { x, y, payload, fill, isLongDateFormat, data = [] } = props;

  if (!payload || !payload.value) return null;

  const currentDate = new Date(payload.value);

  const hasMultipleDates = data.some(
    (item: { date: string | number | Date }) =>
      !isSameDay(new Date(item.date), currentDate)
  );

  let formattedData: string[];

  if (!hasMultipleDates && isToday(currentDate)) {
    formattedData = format(currentDate, 'HH:mm').split(' ');
  } else {
    formattedData = format(
      currentDate,
      isLongDateFormat ? 'EEE do MMM' : 'HH:mm iii'
    ).split(' ');
  }

  return (
    <text x={x} y={y} dy="0" textAnchor="middle" fill={fill}>
      <tspan x={x} dy="1.2em">
        {formattedData[0]}
      </tspan>
      <tspan x={x} dy="1.2em">
        {formattedData[1]} {formattedData[2]}
      </tspan>
    </text>
  );
};
