import { useCalendar } from '@h6s/calendar';
import { useMachine } from '@xstate/react';
import { formatDuration, subMonths } from 'date-fns';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import {
  ArrowRightIcon,
  ClockIcon,
  InfoCircledIcon,
} from '@radix-ui/react-icons';
import { Button, Box, Flex, Popover, Callout } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { t } from 'i18next';
import {
  createDateTimeRangeMachine,
  Error,
  States,
} from './date-time-picker-state';
import { DateRanges } from '../../enums/DateRanges.enum';
import { getTimeForDateRange } from '../../utils/helpers';
import { DatePicker } from './DatePicker';
import { ControlledTimeInput } from './ControlledTimeInput';
import { DateTimeRangeSelection } from './DateTimeRangeSelection';

export interface DateTimeRangePickerEventType {
  start: Date;
  end: Date;
  preset: DateRanges | undefined;
}

interface DateTimeRangePickerProps {
  onChange?: (arg0: DateTimeRangePickerEventType) => void;
  preset?: DateRanges;
  start?: Date | null;
  end?: Date;
  maxDuration: number;
}

const errorToMessage = (error: Error) => {
  return match(error)
    .with(Error.EndNotSet, () => t('endDateNotSet'))
    .with(Error.EndBeforeStart, () => t('endDateBeforeStartDate'))
    .with(Error.ExceedsMaxDuration, () =>
      t('dateRangeExceedsLimit', {
        maxDuration: formatDuration({ months: 2 }),
      })
    )
    .with(Error.EndInFuture, () => t('endDateAfterNow'))
    .otherwise(() => '');
};

export const DateTimeRangePicker = ({
  preset,
  start,
  end,
  maxDuration,
  onChange,
}: DateTimeRangePickerProps) => {
  const [t] = useTranslation();
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({});
  const calendarLeft = useCalendar({
    defaultDate: subMonths(start || new Date(), 1),
  });
  const calendarRight = useCalendar({
    defaultDate: end,
  });

  const dateTimeRangeMachine = useMemo(
    () => createDateTimeRangeMachine(maxDuration),
    [maxDuration]
  );

  const state = useMachine(dateTimeRangeMachine);
  const [snapshot, send] = state;

  useEffect(() => {
    if (start)
      send({
        type: 'SET_PRESET',
        preset,
        start,
        end,
      });
  }, [start, end, send, preset]);

  const toPrev = useCallback(() => {
    calendarLeft.navigation.toPrev();
    calendarRight.navigation.toPrev();
  }, [calendarLeft, calendarRight]);

  const toNext = useCallback(() => {
    calendarLeft.navigation.toNext();
    calendarRight.navigation.toNext();
  }, [calendarLeft, calendarRight]);

  const selectPreset = useCallback(
    (preset: DateRanges) => () => {
      const { start, end } = getTimeForDateRange(preset);

      send({
        type: 'SET_PRESET',
        start,
        end,
        preset,
      });
      calendarLeft.navigation.setDate(subMonths(new Date(), 1));
      calendarRight.navigation.setToday();
    },
    [calendarLeft.navigation, calendarRight.navigation, send]
  );

  const onApply = useCallback(() => {
    if (snapshot.value === States.DONE) {
      setDateTimePickerOpen(false);
      onChange?.(snapshot.context as DateTimeRangePickerEventType);
    } else {
      send({ type: 'VALIDATE' });
    }
  }, [snapshot.value, snapshot.context, onChange, send]);

  const handleOpenChange = (isOpen: boolean) => {
    setDateTimePickerOpen(isOpen);
    if (isOpen) {
      setInitialValues({ start, end, preset });
    } else {
      send({ type: 'RESET', ...initialValues });
      onChange?.(initialValues as DateTimeRangePickerEventType);
    }
  };

  const {
    preset: selectedPreset,
    start: selectedStart,
    end: selectedEnd,
    error,
  } = snapshot.context;

  return (
    <Popover.Root onOpenChange={handleOpenChange} open={dateTimePickerOpen}>
      <Popover.Trigger>
        <Button
          variant="surface"
          color={error === Error.EndNotSet ? 'red' : 'gray'}
          size="3"
          onClick={() => setDateTimePickerOpen(true)}
        >
          <ClockIcon height="20px" width="20px" />
          <DateTimeRangeSelection
            preset={selectedPreset}
            start={selectedStart}
            end={selectedEnd}
          />
        </Button>
      </Popover.Trigger>
      <Popover.Content
        sideOffset={10}
        collisionPadding={16}
        side="bottom"
        align="start"
        maxWidth="800px"
        about="date-picker content"
        className="dateTimeRangePicker"
      >
        <Flex gap="4">
          <Flex direction="column">
            <Flex gap="6">
              <DatePicker
                calendar={calendarLeft}
                onPrev={toPrev}
                state={state}
              />
              <DatePicker
                calendar={calendarRight}
                state={state}
                onNext={toNext}
              />
            </Flex>
            <Flex gap="3" mt="4" align="center">
              <Box width="100%">
                <ControlledTimeInput
                  minutes={
                    selectedStart ? new Date(selectedStart).getMinutes() : 0
                  }
                  hours={selectedStart ? new Date(selectedStart).getHours() : 0}
                  isError={error === Error.EndBeforeStart}
                  onChange={(hours, minutes) => {
                    send({
                      type: 'SET_START_TIME',
                      hours,
                      minutes,
                    });
                  }}
                />
              </Box>
              <Box>
                <ArrowRightIcon />
              </Box>
              <Box width="100%">
                <ControlledTimeInput
                  minutes={selectedEnd ? new Date(selectedEnd).getMinutes() : 0}
                  hours={selectedEnd ? new Date(selectedEnd).getHours() : 0}
                  isError={
                    error === Error.EndBeforeStart ||
                    error === Error.EndInFuture
                  }
                  onChange={(hours, minutes) => {
                    send({
                      type: 'SET_END_TIME',
                      hours,
                      minutes,
                    });
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex direction="column" gap="2">
            {(Object.keys(DateRanges) as Array<keyof typeof DateRanges>).map(
              (preset) => (
                <Button
                  size="2"
                  onClick={selectPreset(DateRanges[preset])}
                  variant={
                    selectedPreset === DateRanges[preset] ? 'soft' : 'surface'
                  }
                  color="gray"
                  key={DateRanges[preset]}
                >
                  {t(`DateRanges.${DateRanges[preset]}`)}
                </Button>
              )
            )}
            <Button mt="auto" onClick={onApply}>
              ⏎ {t('apply')}
            </Button>
          </Flex>
        </Flex>
        {error && (
          <Callout.Root color="red" size="1" mt="2">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>{errorToMessage(error)}</Callout.Text>
          </Callout.Root>
        )}
      </Popover.Content>
    </Popover.Root>
  );
};
