import { getChartEventsData, getTimeRangeInterval } from 'utils/helpers';

import { SensorData } from 'types/sensor';
import { GeneralChartData } from 'types/machine';
import {
  addMilliseconds,
  differenceInMinutes,
  subMilliseconds,
} from 'date-fns';
import { DateRanges } from 'enums/DateRanges.enum';

export const useSensorChartData = (
  maxValue: number,
  minValue: number,
  dateRange: DateRanges | undefined,
  title: string,
  chartData?: SensorData[]
): GeneralChartData[] => {
  const preparedSensorData = chartData?.map<GeneralChartData>((item) => ({
    date: new Date(item.timestamp).getTime(),
    value: item.value,
    label: title,
    showTooltip: true,
  }));

  const dataWithoutMissedValues = preparedSensorData?.reduce<
    GeneralChartData[]
  >((acc, rec, index) => {
    if (index === 0) return [...acc, rec];
    const prevItem = acc[index - 1];
    if (
      dateRange &&
      differenceInMinutes(new Date(rec.date), new Date(prevItem.date)) >
        getTimeRangeInterval(dateRange) &&
      prevItem[rec.label as string]
    ) {
      const nullPeriodStartItem: GeneralChartData = {
        date: addMilliseconds(new Date(prevItem.date), 1).getTime(),
      };
      const nullPeriodEndItem: GeneralChartData = {
        date: subMilliseconds(new Date(rec.date), 1).getTime(),
      };
      return [...acc, nullPeriodStartItem, nullPeriodEndItem, rec];
    }
    return [...acc, rec];
  }, []);

  const firstPointTime = dataWithoutMissedValues?.[0]?.date;

  const lastPointTime =
    dataWithoutMissedValues?.[dataWithoutMissedValues.length - 1]?.date;

  if (!firstPointTime || !lastPointTime) {
    return [];
  }

  return [
    ...dataWithoutMissedValues,
    ...getChartEventsData(
      firstPointTime,
      lastPointTime,
      [],
      maxValue,
      minValue
    ),
  ];
};
