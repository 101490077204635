import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { GeneralOverviewChart } from 'views/ChartView/GeneralOverviewChart';
import { ModelSensorChart } from 'views/ChartView/SensorChart';
import { SensorSensitivitySettings } from 'views/ChartView/SensorChart/SensorSensivitySettings';

import { PermissionsGate } from 'components/PermissionsGate';

import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import { fetchSensorData } from 'services/API/sensor';
import { FetchFilterProps } from 'services/API/machine';

import { getCustomTimeRangeQuery } from 'utils/helpers';

import { SensorReadingResponse } from 'types/sensor';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useLiveUpdateChartOptions } from 'hooks/ChartData/useLiveUpdateChartOptions';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { Flex, Heading, Spinner } from '@radix-ui/themes';
import { useFindMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { Header } from 'components/Header';
import { Breadcrumbs } from 'components/Breadcrumbs';

export const ModelPage: FC = () => {
  const { id } = useParams();
  const [sensorDataSet, setSensorDataSet] =
    useState<SensorReadingResponse | null>(null);
  const { customStartDate, customEndDate } = useChartCacheData();
  const [isLiveUpdate] = useLiveUpdateChartOptions();

  const options: FetchFilterProps = {
    timeRangeQuery: getCustomTimeRangeQuery(customStartDate, customEndDate),
    id,
  };

  const { data } = useQueryWithError<SensorReadingResponse>({
    queryKey: sensorQueryKeys.filteredModelSensorData(options),
    queryFn: () => fetchSensorData(options),
    retry: 0,
  });

  useEffect(() => {
    if (data) {
      setSensorDataSet(data);
    }
  }, [data]);

  const machineData = useFindMachineTree(id).data;
  const machine = machineData ? machineData[0] : undefined;

  return (
    <>
      <Header withTimeRangeSelect>
        <Box>
          <Heading>{machine?.label}</Heading>
          <Breadcrumbs />
        </Box>
      </Header>
      <Flex mx="5" direction="column">
        <GeneralOverviewChart
          options={options}
          liveUpdate={isLiveUpdate}
          additionalSettings={
            <PermissionsGate scopes={[UserScopes.UpdateSensitivity]}>
              <SensorSensitivitySettings id={id || ''} />
            </PermissionsGate>
          }
          isSensorPage
        />
        {sensorDataSet ? (
          Object.keys(sensorDataSet).map((key) => (
            <ModelSensorChart
              dataKey={key}
              key={`${sensorDataSet[key].order}${sensorDataSet[key].title}`}
              options={options}
              liveUpdate={isLiveUpdate}
            />
          ))
        ) : (
          <Flex justify="center" width="100%" p="2">
            <Spinner size="3" />
          </Flex>
        )}
      </Flex>
    </>
  );
};
