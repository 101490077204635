import React, { FC } from 'react';

import { Flex } from '@radix-ui/themes';
import logoSecondary from 'assets/images/logo-secondary.svg';

export const Footer: FC = () => (
  <Flex width="100%" justify="center" align="center" px="5" pb="3">
    <img src={logoSecondary} alt="logo-icon" style={{ height: '50px' }} />
  </Flex>
);
