import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { GeneralOverviewChart } from 'views/ChartView/GeneralOverviewChart';
import { DetailsTable } from 'views/Detail/DetailsTable';
import { DetailCriticalSensorWidgets } from 'views/Detail/DetailCriticalSensorWidgets';
import { OverallChartDateLabel } from 'views/ChartView/OverallChartDateLabel';
import { OverallStates } from 'views/Dashboard/OverallStates';

import { MostCriticalTable } from 'components/MostCriticalTable';

import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { FetchFilterProps } from 'services/API/machine';

import { getCustomTimeRangeQuery } from 'utils/helpers';
import { useLiveUpdateChartOptions } from 'hooks/ChartData/useLiveUpdateChartOptions';
import {
  CalendarIcon,
  Pencil2Icon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';
import {
  Heading,
  Flex,
  Popover,
  Dialog,
  Text,
  Tooltip,
  Button,
  IconButton,
  Card,
} from '@radix-ui/themes';
import * as Toolbar from '@radix-ui/react-toolbar';
import { PermissionsGate } from 'components/PermissionsGate';
import { UserScopes } from 'enums/UserScopes.enum';
import { useFindMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { useTranslation } from 'react-i18next';
import { EventForm } from 'views/Event/EventForm';
import { EventList } from 'views/Event/EventList';
import { Header } from 'components/Header';
import { Breadcrumbs } from 'components/Breadcrumbs';

const boxStyles = {
  display: 'flex',
  gap: '8px',
  marginBottom: '8px',
  marginLeft: '24px',
  marginRight: '24px',
};

export const DetailPage: FC = () => {
  const [isDrawerActive, setIsDrawerActive] = useState(false);
  const [isEventFormOpen, setIsEventFormOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { customStartDate, customEndDate } = useChartCacheData();

  const options: FetchFilterProps = {
    timeRangeQuery: getCustomTimeRangeQuery(customStartDate, customEndDate),
    id,
  };

  const machineData = useFindMachineTree(id).data;
  const machine = machineData ? machineData[0] : undefined;

  const [isLiveUpdate] = useLiveUpdateChartOptions();

  return (
    <>
      <Header withTimeRangeSelect>
        <Box>
          <Heading>{machine?.label}</Heading>
          <Breadcrumbs />
        </Box>
      </Header>
      <Flex gap="2" mb="2" mx="5">
        <OverallStates machineId={id} timeRange={options.timeRangeQuery}>
          <OverallChartDateLabel options={options} />
        </OverallStates>
        <Flex direction="column" gap="2">
          <Box sx={{ minWidth: '220px' }}>
            <Card>
              <PermissionsGate scopes={[UserScopes.Dashboard]}>
                <Toolbar.Root>
                  <Flex align="center" justify="between" mx="1">
                    <Popover.Root
                      onOpenChange={setIsDrawerActive}
                      open={isDrawerActive}
                    >
                      <Toolbar.Button asChild>
                        <Popover.Trigger>
                          <Button variant="ghost" color="gray" mr="1">
                            <CalendarIcon />
                            {t('events.show')}
                          </Button>
                        </Popover.Trigger>
                      </Toolbar.Button>
                      <Popover.Content
                        maxHeight="calc(100vh - 68px)"
                        width="270px"
                      >
                        <EventList selectedDate={new Date('2022-01-01')} />
                      </Popover.Content>
                    </Popover.Root>
                    <Toolbar.Button asChild>
                      <IconButton
                        variant="ghost"
                        color="gray"
                        onClick={() => setIsEventFormOpen(true)}
                      >
                        <Pencil2Icon />
                      </IconButton>
                    </Toolbar.Button>
                  </Flex>
                </Toolbar.Root>
              </PermissionsGate>
            </Card>
          </Box>
          <DetailCriticalSensorWidgets />
        </Flex>
        <MostCriticalTable />
      </Flex>
      <Box sx={boxStyles}>
        <GeneralOverviewChart options={options} liveUpdate={isLiveUpdate} />
      </Box>
      <Box sx={boxStyles}>
        <DetailsTable />
      </Box>
      <PermissionsGate scopes={[UserScopes.Dashboard]}>
        <Dialog.Root onOpenChange={setIsEventFormOpen} open={isEventFormOpen}>
          <Dialog.Content
            style={{
              maxWidth: '700px',
            }}
          >
            <Dialog.Title>
              <Flex justify="between">
                <Text>{t('events.createEvent')}</Text>
                <Tooltip content={t('tooltip.createEvent')}>
                  <IconButton
                    variant="ghost"
                    size="1"
                    color="gray"
                    radius="full"
                  >
                    <QuestionMarkCircledIcon width="20px" height="20px" />
                  </IconButton>
                </Tooltip>
              </Flex>
            </Dialog.Title>
            <EventForm closeDialog={() => setIsEventFormOpen(false)} />
          </Dialog.Content>
        </Dialog.Root>
      </PermissionsGate>
    </>
  );
};
