import React, { useContext } from 'react';
import { User } from 'types/user';

interface AuthContextData {
  userData: User | null;
  onChangeUserData: (userData: User) => void;
  tenantId: string | null;
  onChangeTenantId: (tenantId: string) => void;
  logOut: () => void;
}

export const authContext = React.createContext<AuthContextData>({
  userData: null,
  onChangeUserData: () => {},
  tenantId: null,
  onChangeTenantId: () => {},
  logOut: () => {},
});

export const AuthContextProvider = authContext.Provider;

export const useAuth = (): AuthContextData => useContext(authContext);
