import { subHours, subDays } from 'date-fns';
import { match } from 'ts-pattern';
import { DateRanges } from 'enums/DateRanges.enum';

export const getTimeForDateRange = (preset: DateRanges) => {
  const now = new Date();

  return match(preset)
    .with(DateRanges.HOUR, () => ({
      start: subHours(now, 1),
      end: now,
    }))
    .with(DateRanges.SIXHOURS, () => ({
      start: subHours(now, 6),
      end: now,
    }))
    .with(DateRanges.DAY, () => ({
      start: subHours(now, 24),
      end: now,
    }))
    .with(DateRanges.WEEK, () => ({
      start: subDays(now, 7),
      end: now,
    }))
    .with(DateRanges.TWOWEEKS, () => ({
      start: subDays(now, 14),
      end: now,
    }))
    .with(DateRanges.MONTH, () => ({
      start: subDays(now, 30),
      end: now,
    }))
    .exhaustive();
};
