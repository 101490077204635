import React, { useMemo } from 'react';
import { TextField } from '@radix-ui/themes';

interface ControlledTimeInputProps {
  hours: number;
  minutes: number;
  isError: boolean | undefined;
  onChange: (hours: number, minutes: number) => void;
}

const convertToHoursAndMinutes = (formattedTime: string) => {
  const timeParts = formattedTime.split(':');
  return [Number(timeParts[0]), Number(timeParts[1])];
};

const formatTime = (hours: number, minutes: number) =>
  `${`00${hours}`.slice(-2)}:${`00${minutes}`.slice(-2)}`;

export const ControlledTimeInput = ({
  hours,
  minutes,
  isError,
  onChange,
  ...props
}: ControlledTimeInputProps) => {
  const formattedValue = useMemo(
    () => formatTime(hours, minutes),
    [hours, minutes]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputTime = e.target.value;
    if (typeof onChange === 'function') {
      const [hours, minutes] = convertToHoursAndMinutes(inputTime);
      onChange(hours, minutes);
    }
  };

  return (
    <TextField.Root
      type="time"
      variant="soft"
      color={isError ? 'red' : 'gray'}
      size="2"
      value={formattedValue}
      onChange={handleChange}
      {...props}
    />
  );
};
