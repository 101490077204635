import { assign, setup } from 'xstate';
import { set, differenceInMinutes, isToday } from 'date-fns';
import { DateRanges } from 'enums/DateRanges.enum';

export enum States {
  IDLE = 'IDLE',
  START_SELECTED = 'START_SELECTED',
  DONE = 'DONE',
  VALIDATE = 'VALIDATE',
  ERROR = 'ERROR',
}

export enum Error {
  EndBeforeStart = 'Time',
  ExceedsMaxDuration = 'MaxDuration',
  EndInFuture = 'EndInFuture',
  EndNotSet = 'EndNotSet',
}

export type DateTimePickerEvent =
  | { type: 'SELECT_START'; start: Date }
  | { type: 'SELECT_END'; end: Date }
  | { type: 'SET_PRESET'; start: number; end: number; preset: DateRanges }
  | { type: 'SET_START_TIME' }
  | { type: 'SET_END_TIME' }
  | { type: 'RESET'; event: DatesRange }
  | { type: 'VALIDATE' };

export interface DatesRange {
  start: number;
  end: number;
  preset: DateRanges;
}

export interface TimeRange {
  hours: number;
  minutes: number;
}

export interface ContextTypes {
  start: number;
  end: number;
  preset: DateRanges;
  error: Error | null;
}

function validateDateTimeRange(start: Date, end: Date, maxDuration: number) {
  if (!end) return Error.EndNotSet;
  if (end > new Date()) return Error.EndInFuture;
  if (end < start) return Error.EndBeforeStart;
  const duration = differenceInMinutes(end, start);
  if (duration > maxDuration) return Error.ExceedsMaxDuration;
  return null;
}

const getDefaultStartDayTime = ({
  context,
  event,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any;
}) => {
  if (context.start) {
    return set(context.start, event);
  }
  return set(new Date(), event);
};

const getDefaultEndDayTime = ({
  context,
  event,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any;
}) => {
  if (context.end) {
    return set(context.end, event);
  }
  if (context.start) {
    return set(context.start, event);
  }
  return set(new Date(), event);
};

const determineStartEndDateTime = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { context, event }: { context: any; event: any },
  reverse: boolean
) => {
  const isEndBeforeStart = context.start > event.end;
  if (reverse ? !isEndBeforeStart : isEndBeforeStart) {
    return context.start;
  }
  return event.end;
};

export const createDateTimeRangeMachine = (maxDuration: number) =>
  setup({
    actions: {
      setPreset: assign({
        start: ({ event }) => event.start,
        end: ({ event }) => event.end,
        preset: ({ event }) => event.preset,
        error: null,
      }),
      setStartDate: assign({
        start: ({ event }) => event.start,
        preset: undefined,
        end: undefined,
        error: null,
      }),
      setEndDate: assign(({ context, event }) => {
        const start = determineStartEndDateTime({ context, event }, true);
        let end = determineStartEndDateTime({ context, event }, false);

        const isEndDefaultTime = end.getHours() === 0 && end.getMinutes() === 0;

        if (isEndDefaultTime) {
          if (isToday(end)) {
            end = new Date();
          } else {
            end = set(end, { hours: 23, minutes: 59 });
          }
        }

        return {
          end,
          start,
          preset: undefined,
          error: validateDateTimeRange(start, end, maxDuration),
        };
      }),
      setStartTime: assign({
        start: getDefaultStartDayTime,
        preset: undefined,
        error: ({ context, event }) =>
          validateDateTimeRange(
            getDefaultStartDayTime({ context, event }),
            context.end,
            maxDuration
          ),
      }),
      setEndTime: assign({
        end: getDefaultEndDayTime,
        preset: undefined,
        error: ({ context, event }) =>
          validateDateTimeRange(
            context.start,
            getDefaultEndDayTime({ context, event }),
            maxDuration
          ),
      }),
      reset: assign({
        start: ({ event }) => event.start,
        end: ({ event }) => event.end,
        preset: ({ event }) => event.preset,
        error: null,
      }),
      validate: assign({
        error: ({ context }) =>
          validateDateTimeRange(context.start, context.end, maxDuration),
      }),
    },
    guards: {
      isContextValid: ({ context }) => !context.error,
    },
  }).createMachine({
    id: 'date-time-picker',
    initial: States.IDLE,
    context: {
      start: null,
      end: null,
      preset: null,
      error: null,
    },
    on: {
      RESET: {
        target: `.${States.DONE}`,
        actions: 'reset',
      },
      SET_PRESET: {
        target: `.${States.VALIDATE}`,
        actions: 'setPreset',
      },
      SET_START_TIME: {
        actions: 'setStartTime',
      },
      SET_END_TIME: {
        target: `.${States.VALIDATE}`,
        actions: 'setEndTime',
      },
      VALIDATE: {
        actions: 'validate',
      },
    },
    states: {
      IDLE: {
        on: {
          SELECT_START: {
            target: States.START_SELECTED,
            actions: 'setStartDate',
          },
        },
      },
      START_SELECTED: {
        on: {
          SELECT_END: {
            target: States.VALIDATE,
            actions: 'setEndDate',
          },
        },
      },
      VALIDATE: {
        always: [
          { target: States.DONE, guard: 'isContextValid' },
          { target: States.ERROR },
        ],
      },
      ERROR: {
        on: {
          SELECT_START: {
            target: States.START_SELECTED,
            actions: 'setStartDate',
          },
          SET_END_TIME: {
            target: States.VALIDATE,
            actions: 'setEndTime',
          },
        },
      },
      DONE: {
        on: {
          SELECT_START: {
            target: States.START_SELECTED,
            actions: 'setStartDate',
          },
          SET_END_TIME: {
            target: States.VALIDATE,
            actions: 'setEndTime',
          },
        },
      },
    },
  });
