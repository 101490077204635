import React from 'react';
import logo from 'assets/images/logo.svg';
import { generatePath, useNavigate } from 'react-router-dom';
import { Navigation } from 'components/Navigation';
import { AppRoutes } from 'enums/Routes.enum';
import {
  Flex,
  Text,
  Link,
  Separator,
  IconButton,
  Button,
  Dialog,
  Card,
} from '@radix-ui/themes';
import {
  BellIcon,
  ChatBubbleIcon,
  EnvelopeClosedIcon,
  GearIcon,
  HomeIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';
import { useDemoEnvironment } from 'hooks/useDemoEnvironment';
import { UserBar } from 'components/UserBar';
import { useTranslation } from 'react-i18next';

export const Sidebar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDemoEnvironment = useDemoEnvironment();

  return (
    <div
      style={{
        backgroundColor: 'var(--color-panel-solid)',
        borderRight: '1px solid var(--gray-3)',
        display: 'flex',
        boxShadow: 'var(--shadow-6)',
        height: '100%',
        left: 0,
        position: 'fixed',
        zIndex: 10,
        width: '210px',
      }}
    >
      <Flex direction="column" gap="2" width="100%">
        <Flex
          height="70px"
          align="center"
          p="2"
          mt="2"
          width="100%"
          onClick={() => navigate(generatePath(AppRoutes.Dashboard))}
        >
          <img src={logo} alt="Logo" width="75%" />
        </Flex>
        <Separator size="4" />
        <Navigation />
        <Separator size="4" />
        <Flex align="center" p="4" gap="2" justify="between">
          <UserBar />
          <Flex justify="end" gap="4">
            {isDemoEnvironment ? (
              <>
                <IconButton variant="ghost" radius="full" size="3" color="gray">
                  <GearIcon />
                </IconButton>
                <IconButton variant="ghost" radius="full" size="3" color="gray">
                  <QuestionMarkCircledIcon />
                </IconButton>
                <IconButton variant="ghost" radius="full" size="3" color="gray">
                  <BellIcon />
                </IconButton>
              </>
            ) : (
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button variant="soft" radius="full" size="2" color="gray">
                    <QuestionMarkCircledIcon /> {t('help')}
                  </Button>
                </Dialog.Trigger>
                <Dialog.Content minWidth="400px">
                  <Dialog.Title>{t('helpAndContact')}</Dialog.Title>
                  <Dialog.Description size="3">
                    <Flex justify="between" py="2">
                      <Card variant="ghost">
                        <Flex align="center" gapX="2" mb="1">
                          <EnvelopeClosedIcon />
                          <Text weight="bold" as="div">
                            {t('email')}
                          </Text>
                        </Flex>
                        <Link target="_blank" href="mailto:info@ai-omatic.com">
                          info@ai-omatic.com
                        </Link>
                      </Card>
                      <Card variant="ghost">
                        <Flex align="center" gapX="2" mb="1">
                          <ChatBubbleIcon />
                          <Text weight="bold" as="div">
                            {t('phone')}
                          </Text>
                        </Flex>
                        <Link target="_blank" href="tel:+4940226597370">
                          +49 40 226 597 370
                        </Link>
                      </Card>
                      <Card variant="ghost">
                        <Flex align="center" gapX="2" mb="1">
                          <HomeIcon />
                          <Text weight="bold" as="div">
                            {t('address')}
                          </Text>
                        </Flex>
                        <Link
                          target="_blank"
                          href="https://maps.app.goo.gl/jqmTfBSpmdNtsiJu9"
                        >
                          Kleine Johannisstraße 9,
                          <br />
                          20457 Hamburg,
                          <br />
                          Germany
                        </Link>
                      </Card>
                    </Flex>
                  </Dialog.Description>
                </Dialog.Content>
              </Dialog.Root>
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
